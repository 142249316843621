export default class Navigator {
    $el;
    $$triggers;

    constructor($el) {
        this.$el = $el;
        this.$$triggers = this.$el.querySelectorAll('.js-navigatorTrigger');

        this.initListeners();
    }

    initListeners() {
        this.$$triggers.forEach(($trigger) => {
            $trigger.addEventListener('pointerdown', this.onTriggerClick.bind(this), false);
        });
    }

    onTriggerClick(e) {
        const $trigger = e.currentTarget;
        const id = $trigger.id ?? null;

        // Reset all
        this.$$triggers.forEach(($trigger) => $trigger.classList.remove('is-active'));

        // Set active state
        $trigger.classList.add('is-active');

        // Dispatch event
        this.$el.dispatchEvent(new CustomEvent('navigator:change', { bubbles: true, detail: id }));

        // Set interacted state
        this.$el.classList.add('has-interacted');
    }
}
