export default class Interface {
    $el;
    $$panels;
    $navigator;

    constructor($el) {
        this.$el = $el;
        this.$$panels = this.$el.querySelectorAll('.js-interfacePanel');
        this.$navigator = this.$el.querySelector('.js-navigator');

        this.initListeners();
    }

    initListeners() {
        this.$navigator.addEventListener('navigator:change', this.onNavigatorChange.bind(this));
    }

    onNavigatorChange(e) {
        const id = e.detail;

        this.$$panels.forEach(($panel) => {
            $panel.classList.toggle('is-active', $panel.dataset.id === id);
        });
    }
}
