import App from './App';

import Equinox from './components/Equinox';
import Interface from './components/Interface';
import Modules from './components/Modules';
import Navigator from './components/Navigator';
import TextSplit from './components/TextSplit';

window.addEventListener('DOMContentLoaded', () => {
    window.__APP__ = new App([
        { component: Equinox, name: 'equinox' },
        { component: Interface, name: 'interface' },
        { component: Modules, name: 'modules' },
        { component: Navigator, name: 'navigator' },
        { component: TextSplit, name: 'textSplit' },
    ]);
});
