export default class Modules {
    $el;
    $$panels;
    $$triggers;

    constructor($el) {
        this.$el = $el;
        this.$$panels = this.$el.querySelectorAll('.js-modulesPanel');
        this.$$triggers = this.$el.querySelectorAll('.js-modulesTrigger');

        this.initListeners();
    }

    initListeners() {
        this.$$triggers.forEach(($trigger) => {
            $trigger.addEventListener('pointerdown', this.onTriggerClick.bind(this));
        });
    }

    onTriggerClick(e) {
        const id = e.currentTarget.id;

        if (id) {
            this.$$panels.forEach(($panel) => {
                $panel.classList.toggle('is-active', $panel.dataset.id === id);
            });

            this.$$triggers.forEach(($trigger) => {
                $trigger.classList.toggle('is-active', $trigger.id === id);
            });
        }
    }
}
